import dynamic from 'next/dynamic';

import { WithDataTestId } from '@/types/with-data-testid';
import { getBackgroundSectionClassName } from '@/utils/marketing-landing-page';

import { BackgroundColors } from '../types';

import clsx from 'clsx';

const SecondaryHeading = dynamic(
  () => import('@/components/typography').then((mod) => mod.SecondaryHeading),
  { ssr: false }
);

type SectionModuleWrapperProps = {
  title?: string;
  backgroundColor?: BackgroundColors;
  children: React.ReactNode;
  className?: string;
  id?: string;
  containerClassName?: string;
} & WithDataTestId;

export function SectionModuleWrapper({
  title,
  backgroundColor,
  children,
  className,
  containerClassName,
  dataTestId,
  id,
}: SectionModuleWrapperProps) {
  return (
    <section
      className={clsx(
        'py-12 overflow-hidden',
        getBackgroundSectionClassName(backgroundColor),
        className
      )}
      id={id}
      data-testid={dataTestId}
    >
      <div className={clsx(containerClassName ?? 'medium-container')}>
        {title && <SecondaryHeading className="mb-6">{title}</SecondaryHeading>}
        {children}
      </div>
    </section>
  );
}
