import { SocialReviewDto } from '@/lib/marketing-page/dto/modules/social-review-cards-module.dto';

export const socialReviewsEnMock: SocialReviewDto[] = [
  {
    name: 'Marina Stamos',
    stars: 5,
    description:
      'I have an office at 50. S. 16th - look forward to going there every day. Amazing staff, beautiful, clean and welcoming physical layout and decor. Great light, vibe.',
    link: 'https://goo.gl/maps/7ujCGju3j4JSnFvd9',
  },
  {
    name: 'Nick Petros',
    stars: 5,
    description:
      "Fantastic place to work.  Great light pretty much every day. Community managers do a good job taking care of us (we're often surprised with extras).  Couldn't be happier to be there.",
    link: 'https://goo.gl/maps/cC8GmGRGMZKm9bcr6',
  },
  {
    name: "William Jay O'Connell",
    stars: 5,
    description:
      'We love Industrious Seaport. Beautiful workspace and the Community Team, Derek and Jason create amazing experiences!',
    link: 'https://g.co/kgs/rtFpCv',
  },
  {
    name: 'Thomas Nevin',
    stars: 5,
    description:
      'I really love having an office at Industrious in Broadway Plaza.  The architecture is chic and modern and designed to allow natural light to flow throughout the entire workspace. My clients love the convenient location. I like the variety of meeting spaces that are flexible enough for informal meetings with one or two people over coffee or more formal groups of 10 or 12 attending a presentation.  The staff is very supportive and always on point.  What a fantastic opportunity to grow your business while working in a beautiful, functional, and positive space in the heart of downtown Walnut Creek!',
    link: 'https://goo.gl/maps/EuAzkiqcvB77kneY9',
  },
  {
    name: 'Stephanie Baiocchi',
    stars: 5,
    description:
      "Oh my gosh where to begin. I literally cannot say enough good things about this amazing co-working space. Not only has Industrious achieved the perfect home-away-from-home coffee shop-esque aesthetic, they've managed to create a community to go along with it. From the breakfasts to happy hours and events in between, the staff encourages members to get to know each other but not in the cheesy forced networking way.",
    link: 'https://goo.gl/maps/orvMHy5R3CRGDZN38',
  },
];

export const socialReviewsFrMock: SocialReviewDto[] = [
  {
    name: 'Marina Stamos',
    stars: 5,
    description:
      'I have an office at 50. S. 16th - look forward to going there every day. Amazing staff, beautiful, clean and welcoming physical layout and decor. Great light, vibe.',
    link: 'https://goo.gl/maps/7ujCGju3j4JSnFvd9',
  },
  {
    name: 'Nick Petros',
    stars: 5,
    description:
      "Fantastic place to work.  Great light pretty much every day. Community managers do a good job taking care of us (we're often surprised with extras).  Couldn't be happier to be there.",
    link: 'https://goo.gl/maps/cC8GmGRGMZKm9bcr6',
  },
  {
    name: "William Jay O'Connell",
    stars: 5,
    description:
      'We love Industrious Seaport. Beautiful workspace and the Community Team, Derek and Jason create amazing experiences!',
    link: 'https://g.co/kgs/rtFpCv',
  },
  {
    name: 'Thomas Nevin',
    stars: 5,
    description:
      'I really love having an office at Industrious in Broadway Plaza.  The architecture is chic and modern and designed to allow natural light to flow throughout the entire workspace. My clients love the convenient location. I like the variety of meeting spaces that are flexible enough for informal meetings with one or two people over coffee or more formal groups of 10 or 12 attending a presentation.  The staff is very supportive and always on point.  What a fantastic opportunity to grow your business while working in a beautiful, functional, and positive space in the heart of downtown Walnut Creek!',
    link: 'https://goo.gl/maps/EuAzkiqcvB77kneY9',
  },
  {
    name: 'Stephanie Baiocchi',
    stars: 5,
    description:
      "Oh my gosh where to begin. I literally cannot say enough good things about this amazing co-working space. Not only has Industrious achieved the perfect home-away-from-home coffee shop-esque aesthetic, they've managed to create a community to go along with it. From the breakfasts to happy hours and events in between, the staff encourages members to get to know each other but not in the cheesy forced networking way.",
    link: 'https://goo.gl/maps/orvMHy5R3CRGDZN38',
  },
];

export const socialReviewsNlMock: SocialReviewDto[] = [
  {
    name: 'Marina Stamos',
    stars: 5,
    description:
      'Ik heb een kantoor op 50. S. 16th - ik kijk er elke dag naar uit om er naartoe te gaan. Geweldig personeel, mooie, schone en uitnodigende indeling en inrichting. Geweldig licht, sfeer.',
    link: 'https://goo.gl/maps/7ujCGju3j4JSnFvd9',
  },
  {
    name: 'Nick Petros',
    stars: 5,
    description:
      "Fantastische plek om te werken. Vrijwel elke dag geweldig licht. Community managers zorgen goed voor ons (we worden vaak verrast met extra's). Ik ben heel blij dat ik er werk.",
    link: 'https://goo.gl/maps/cC8GmGRGMZKm9bcr6',
  },
  {
    name: "William Jay O'Connell",
    stars: 5,
    description:
      'Wij houden van Industrious Seaport. Prachtige werkruimte en het Community Team, Derek en Jason zorgen voor een geweldige sfeer!',
    link: 'https://g.co/kgs/rtFpCv',
  },
  {
    name: 'Thomas Nevin',
    stars: 5,
    description:
      'Ik vind het geweldig om een kantoor te hebben bij Industrious in Broadway Plaza. De architectuur is chic en modern en ontworpen om natuurlijk licht door de hele werkruimte te laten binnenvallen. Mijn klanten vinden de locatie geweldig. Ik waardeer de verscheidenheid aan vergaderruimtes die flexibel genoeg zijn voor informele vergaderingen met één of twee mensen onder het genot van een kopje koffie of meer formele groepen van 10 of 12 personen die een presentatie bijwonen. Het personeel is erg behulpzaam en altijd beschikbaar. Wat een fantastische manier om je bedrijf te laten groeien terwijl je werkt in een mooie, functionele en positieve ruimte in het hart van het centrum van Walnut Creek!',
    link: 'https://goo.gl/maps/EuAzkiqcvB77kneY9',
  },
  {
    name: 'Stephanie Baiocchi',
    stars: 5,
    description:
      'Waar moet ik beginnen? Ik kan letterlijk niet genoeg positieve dingen zeggen over deze geweldige co-working ruimte. Industrious heeft niet alleen de perfecte home-away-from-home coffeeshop-achtige uitstraling, maar ze zijn er ook in geslaagd om een bijbehorende community te creëren. De medewerkers moedigen de leden aan om elkaar te leren kennen, maar dan niet op een geforceerde manier, met ontbijtjes en happy hours en evenementen tussendoor.',
    link: 'https://goo.gl/maps/orvMHy5R3CRGDZN38',
  },
];

export const socialReviewsMocks: Record<string, SocialReviewDto[]> = {
  en: socialReviewsEnMock,
  fr: socialReviewsFrMock,
  nl: socialReviewsNlMock,
};
