import { useTranslations } from 'next-intl';

import { CircleLoader } from '../CircleLoader';
import LoepIcon from '../icons/LoepIcon';

type SearchIndicatorProps = React.ComponentPropsWithoutRef<'button'> & {
  isLoading?: boolean;
};

export default function SearchIndicator({
  isLoading,
  ...rest
}: SearchIndicatorProps) {
  const t = useTranslations('search');

  return (
    <button
      className="m-[2px] rounded-full bg-teal-40 w-[46px] h-[46px] flex items-center justify-center min-w-[46px]"
      data-testid="button-locations-input"
      aria-label={t('search-locations-aria-label')}
      {...rest}
    >
      {isLoading ? <CircleLoader /> : <LoepIcon className="fill-white" />}
    </button>
  );
}
