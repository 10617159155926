import { LinkProps as NextLinkProps } from 'next/link';
import { ReactNode } from 'react';

import { Link as NextLink } from '@/components/Link';
import { Locale } from '@/utils';

import { LinkButtonThemes } from './LinkButtonThemes';

import clsx from 'clsx';

type SecondaryLinkButtonProps = {
  className?: string;
  children?: ReactNode;
  theme?: LinkButtonThemes;
} & NextLinkProps &
  React.ComponentPropsWithoutRef<'a'>;

function SecondaryLinkButtonFactory(props: SecondaryLinkButtonProps) {
  if (props.theme === LinkButtonThemes.Home) {
    return HomeHeaderSecondaryLinkButton(props);
  }
  if (props.theme === LinkButtonThemes.White) {
    return WhiteSecondaryLinkButton(props);
  }
  return DefaultSecondaryLinkButton(props);
}

function HomeHeaderSecondaryLinkButton(props: SecondaryLinkButtonProps) {
  const { className, ...otherProps } = props;

  return (
    <BaseSecondaryLinkButton
      {...otherProps}
      className={clsx(
        'bg-transparent text-teal-40 border-teal-40 hover:bg-teal-40 hover:text-white',
        'header-transparent:border-white header-transparent:text-white',
        className
      )}
    />
  );
}

function DefaultSecondaryLinkButton(props: SecondaryLinkButtonProps) {
  const { className, ...otherProps } = props;

  return (
    <BaseSecondaryLinkButton
      {...otherProps}
      className={clsx(
        'text-teal-40 border-teal-40 hover:bg-teal-40 hover:text-white',
        className
      )}
    />
  );
}

function WhiteSecondaryLinkButton(props: SecondaryLinkButtonProps) {
  const { className, ...otherProps } = props;

  return (
    <BaseSecondaryLinkButton
      {...otherProps}
      className={clsx(
        'text-white border-white hover:bg-white hover:text-teal-40',
        className
      )}
    />
  );
}

function BaseSecondaryLinkButton(props: SecondaryLinkButtonProps) {
  const { className, children, locale, ...nextLinkProps } = props;

  return (
    <NextLink
      {...nextLinkProps}
      className={clsx(
        'font-medium rounded-full border-2 w-fit px-5 py-2',
        className
      )}
      locale={locale as Locale}
    >
      {children}
    </NextLink>
  );
}

export { SecondaryLinkButtonFactory as SecondaryLinkButton };
