import { useTranslations } from 'next-intl';
import { useId } from 'react';
import Select, {
  ControlProps,
  DropdownIndicatorProps,
  MenuProps,
  OptionProps,
  ValueContainerProps,
  components,
} from 'react-select';

import { SearchType } from '@/components/search/search-type.enum';

import clsx from 'clsx';

type Option = {
  label: string;
  value: SearchType;
};

type SelectSearchTypeProps = {
  value: SearchType;
  onChange: (selection: SearchType) => void;
};

export default function SelectSearchType({
  value,
  onChange,
}: SelectSearchTypeProps) {
  const t = useTranslations('home');
  const id = useId();

  const options: Option[] = [
    {
      label: t('select-products.office'),
      value: SearchType.Location,
    },
    {
      label: t('select-products.coworking'),
      value: SearchType.Coworking,
    },
    {
      label: t('select-products.meeting-room'),
      value: SearchType.MeetingRoom,
    },
  ];

  const selection = options.find((option) => option.value === value);

  return (
    <Select
      instanceId={id}
      className="min-w-[190px] max-md:w-[calc(100%-20px)]"
      options={options}
      value={selection}
      components={{
        IndicatorSeparator: () => null,
        Control,
        DropdownIndicator,
        Option: OptionComponent,
        ValueContainer,
        Menu,
      }}
      isMulti={false}
      isSearchable={false}
      onChange={(x) => x && onChange(x.value)}
    />
  );
}

const Control = ({ children, ...props }: ControlProps<Option>) => {
  return (
    <components.Control
      {...props}
      className="!border-none hover:!border-none !cursor-pointer !shadow-none md:!pr-5"
    >
      {children}
    </components.Control>
  );
};

const DropdownIndicator = ({
  children,
  ...props
}: DropdownIndicatorProps<Option>) => {
  const menuIsOpen = props.selectProps.menuIsOpen;

  return (
    <components.DropdownIndicator
      {...props}
      className={clsx(
        '!py-0 !px-0 !transition !ease-in-out !duration-300 !text-teal-40',
        menuIsOpen && 'rotate-180'
      )}
    >
      {children}
    </components.DropdownIndicator>
  );
};

const OptionComponent = ({
  children,
  className,
  ...props
}: OptionProps<Option>) => {
  const isFocus = props.isFocused;
  return (
    <components.Option
      {...props}
      className={clsx(
        className,
        '!bg-white hover:!bg-almond-90  !text-black !flex gap-2 !cursor-pointer !px-5',
        isFocus && '!bg-almond-90'
      )}
    >
      {children}
    </components.Option>
  );
};

const ValueContainer = ({
  children,
  className,
  ...props
}: ValueContainerProps<Option>) => {
  return (
    <components.ValueContainer
      {...props}
      className={clsx(className, '!text-start')}
    >
      {children}
    </components.ValueContainer>
  );
};

const Menu = ({ children, className, ...props }: MenuProps<Option>) => {
  return (
    <components.Menu className={clsx(className, '!mt-3 shadow')} {...props}>
      {children}
    </components.Menu>
  );
};
