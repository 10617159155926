import { useTranslations } from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';

import starIcon from '@/public/icons/starIcon.svg';

export type SocialReviewCardProps = {
  name: string;
  stars: number;
  description: string;
  link: string;
};

export default function SocialReviewCard({
  name,
  description,
  stars,
  link,
}: SocialReviewCardProps) {
  const t = useTranslations('general');
  return (
    <Link
      href={link}
      className="flex flex-col items-center gap-4 text-sm text-center bg-white border border-grey-95 rounded-sm py-6 px-4 h-full hover:shadow"
      target="_blank"
      rel="noopener"
    >
      <p className="font-medium">{name}</p>
      <div className="flex gap-[1px]">
        {[...Array(stars)].map((s, index) => (
          <Image
            key={`star-${index}`}
            width={13}
            height={12}
            src={starIcon}
            alt={t('alt-text-star-icon')}
          />
        ))}
      </div>
      <p className="line-clamp-4">{description}</p>
    </Link>
  );
}
