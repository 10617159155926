import clsx from 'clsx';

type VerticalDividerProps = {
  className?: string;
};

export function VerticalDivider({ className }: VerticalDividerProps) {
  return (
    <div className={clsx('min-w-[1px] max-w-[1px] bg-grey-60', className)} />
  );
}
