import { useSearchParams } from 'next/navigation';

// eslint-disable-next-line no-restricted-imports
import { useRouter as useNextRouter } from '@/navigation';
import {
  getAllParamsFromSearchParams,
  stringifyUrl,
} from '@/utils/stringify-url';

export function useRouter() {
  const searchParams = useSearchParams();
  const { push: routerPush, replace: routerReplace, refresh } = useNextRouter();
  const allParams = getAllParamsFromSearchParams(searchParams);

  const supplier = allParams.supplier ? allParams.supplier : null;

  const push: typeof routerPush = (href, options) => {
    return routerPush(
      stringifyUrl(href, {
        ...(supplier && {
          supplier,
        }),
      }),
      options
    );
  };

  const replace: typeof routerReplace = (href, options) => {
    return routerReplace(
      stringifyUrl(href, {
        ...(supplier && {
          supplier,
        }),
      }),
      options
    );
  };

  return {
    push,
    replace,
    refresh,
  };
}
