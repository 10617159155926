import clsx from 'clsx';

type CarouselDotProps = {
  id: string;
  isActive: boolean;
  onClick: () => void;
};

export function CarouselDot(props: CarouselDotProps) {
  const { id, isActive, onClick } = props;

  return (
    <div
      key={`dot-${id}`}
      data-testid={`carousel-dot-${id}`}
      className={clsx(
        'block bg-teal-5 rounded-full',
        isActive && 'w-3 h-3',
        !isActive && 'w-[9px] h-[9px] opacity-50 hover:opacity-100'
      )}
      onClick={onClick}
    ></div>
  );
}
