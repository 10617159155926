'use client';

import { useEffect, useRef, useState } from 'react';

import { captureMessage } from '@/utils/sentry';

import { VideoProps } from '../types';

import clsx from 'clsx';

type BackgroundVideoProps = { className?: string } & VideoProps;

export function BackgroundVideo({ className, ...props }: BackgroundVideoProps) {
  const vidRef = useRef<HTMLVideoElement>(null);
  const [autoPlay, setAutoPlay] = useState(false);

  useEffect(() => {
    if (!vidRef.current) return;
    // The preload value is ignored if the video is set to autoplay
    // so to improve our performance and use the preload value
    // we need to play the video manually
    const video = vidRef.current;
    const isPlaying =
      video.currentTime > 0 &&
      !video.paused &&
      !video.ended &&
      video.readyState > video.HAVE_CURRENT_DATA;

    if (!isPlaying) {
      const playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise.catch((error) => {
          setAutoPlay(true);
          captureMessage(
            `Failed to autoplay the video with error :${JSON.stringify(error)}`
          );
        });
      }
    }
  }, []);

  return (
    <video
      ref={vidRef}
      className={clsx('object-cover object-center w-full h-full', className)}
      loop
      muted
      playsInline
      preload="metadata"
      autoPlay={autoPlay}
    >
      <source {...props} />
    </video>
  );
}
